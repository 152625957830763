import React, { useCallback, useEffect } from "react";
import "./App.css";
import Container from "./Container";
import Joke from "./Joke";
import Controls from "./Controls";

function App() {
	const [joke, setJoke] = React.useState<string | null>(null);
	const [tone, setTone] = React.useState<string>("emotional");
	const [style, setStyle] = React.useState<string>("sarcastic");
	const [format, setFormat] = React.useState<string>("default");

	const getAndSetJoke = useCallback(() => {
		fetch(
			`https://km4466xxo7.execute-api.ap-southeast-2.amazonaws.com/default/brad-jokes-joke?tone=${tone}&format=${format}&style=${style}`,
		)
			.then((response) => response.json())
			.then((data) => setJoke(data.joke))
			.catch((error) => {
				console.error(error);
				setJoke("An error occurred. Please try again.\n\nThis is not a joke.");
			});
	}, [tone, format, style]);

	useEffect(() => {
		getAndSetJoke();
	}, [getAndSetJoke]);

	const handleOnAgain = useCallback(() => {
		setJoke(null);
		getAndSetJoke();
	}, [getAndSetJoke]);

	const handleSetTone = useCallback((tone: string) => {
		setJoke(null);
		setTone(tone);
	}, []);

	const handleSetFormat = useCallback((format: string) => {
		setJoke(null);
		setFormat(format);
	}, []);

	const handleSetStyle = useCallback((style: string) => {
		setJoke(null);
		setStyle(style);
	}, []);

	return (
		<Container
			controls={
				<Controls
					onFormatChange={handleSetFormat}
					onStyleChange={handleSetStyle}
					onToneChange={handleSetTone}
				/>
			}
		>
			<Joke joke={joke} onAgain={handleOnAgain} />
		</Container>
	);
}

export default App;
