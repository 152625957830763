const image = require("./logo.svg").default;

export default function Container({
	children,
	controls,
}: {
	children: React.ReactNode;
	controls: React.ReactNode;
}) {
	return (
		<div className="flex min-w-full flex-col justify-between">
			<div className="mx-auto mb-4 max-w-2xl text-center sm:mb-8">
				<img src={image} alt="logo" className="mx-auto size-32 sm:size-40" />
				<h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
					Brad Jokes
				</h2>
				<p className="text-sm leading-7 text-gray-600 sm:text-base">
					Bad Dad Jokes by Brad & ChatGPT
				</p>
			</div>
			<div className="mx-auto mb-4 sm:mb-6 flex min-w-full flex-grow items-center justify-center">
				<div className="max-w-6xl rounded-lg bg-white shadow">{children}</div>
			</div>
			<div className="mx-auto max-w-xl">{controls}</div>
		</div>
	);
}
