export default function Joke({
	joke,
	onAgain,
}: {
	joke: string | null;
	onAgain: () => void;
}) {
	const [setup, ...punchlines] = joke?.split("\n\n") || [];
	return (
		<div className="p-8 sm:p-12">
			<div className="text-center">
				<div className="mb-8 space-y-6 sm:space-y-10 sm:mb-12">
					<h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
						{setup || "Drum roll..."}
					</h2>
					{setup &&
						!!punchlines.length &&
						punchlines.map((line) => (
							<p className="text-lg leading-8 text-gray-600 mx-automax-w-xl sm:text-xl">
								{line}
							</p>
						))}
					{!setup && !punchlines && (
						<p className="text-lg leading-8 text-gray-600 mx-automax-w-xl sm:text-xl">
							🥁
						</p>
					)}
				</div>
				<button
					type="button"
					className="rounded-lg bg-white px-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 py-2.5 hover:bg-gray-50"
					onClick={onAgain}
				>
					Again
				</button>
			</div>
		</div>
	);
}
