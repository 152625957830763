import React from "react";
import Select from "./Select";

const toneOptions = [
	{ id: "emotional", label: "Emotional" },
	{ id: "authoritative", label: "Authoritative" },
	{ id: "clinical", label: "Clinical" },
	{ id: "cold", label: "Cold" },
	{ id: "confident", label: "Confident" },
	{ id: "confrontational", label: "Confrontational" },
	{ id: "cynical", label: "Cynical" },
	{ id: "empathetic", label: "Empathetic" },
	{ id: "formal", label: "Formal" },
	{ id: "friendly", label: "Friendly" },
	{ id: "humorous", label: "Humorous" },
	{ id: "informal", label: "Informal" },
	{ id: "inspirational", label: "Inspirational" },
	{ id: "ironic", label: "Ironic" },
	{ id: "optimistic", label: "Optimistic" },
	{ id: "pessimistic", label: "Pessimistic" },
	{ id: "playful", label: "Playful" },
	{ id: "sarcastic", label: "Sarcastic" },
	{ id: "serious", label: "Serious" },
	{ id: "sympathetic", label: "Sympathetic" },
	{ id: "tentative", label: "Tentative" },
	{ id: "warm", label: "Warm" },
];
const styleOptions = [
	{ id: "sarcastic", label: "Sarcastic" },
	{ id: "sarcastic", label: "Default" },
	{ id: "academic", label: "Academic" },
	{ id: "analytical", label: "Analytical" },
	{ id: "argumentative", label: "Argumentative" },
	{ id: "conversational", label: "Conversational" },
	{ id: "creative", label: "Creative" },
	{ id: "critical", label: "Critical" },
	{ id: "descriptive", label: "Descriptive" },
	{ id: "epigrammatic", label: "Epigrammatic" },
	{ id: "epistolary", label: "Epistolary" },
	{ id: "expository", label: "Expository" },
	{ id: "informative", label: "Informative" },
	{ id: "instructive", label: "Instructive" },
	{ id: "journalistic", label: "Journalistic" },
	{ id: "metaphorical", label: "Metaphorical" },
	{ id: "narrative", label: "Narrative" },
	{ id: "persuasive", label: "Persuasive" },
	{ id: "poetic", label: "Poetic" },
	{ id: "satirical", label: "Satirical" },
	{ id: "technical", label: "Technical" },
];
const formatOptions = [
	{ id: "default", label: "Default" },
	{ id: "blog", label: "Blog" },
	{ id: "brochure", label: "Brochure" },
	{ id: "case_study", label: "Case Study" },
	{ id: "concise", label: "Concise" },
	{ id: "eli5", label: "ELI5" },
	{ id: "email_campaign", label: "Email Campaign" },
	{ id: "essay", label: "Essay" },
	{ id: "extreme_detail", label: "Extreme Detail" },
	{ id: "faq", label: "FAQ" },
	{ id: "infographic", label: "Infographic" },
	{ id: "interview", label: "Interview" },
	{ id: "listicle", label: "Listicle" },
	{ id: "memo", label: "Memo" },
	{ id: "news", label: "News" },
	{ id: "newsletter", label: "Newsletter" },
	{ id: "opinion", label: "Opinion" },
	{ id: "podcast_script", label: "Podcast Script" },
	{ id: "poem", label: "Poem" },
	{ id: "policy_document", label: "Policy Document" },
	{ id: "presentation", label: "Presentation" },
	{ id: "press_release", label: "Press Release" },
	{ id: "product_description", label: "Product Description" },
	{ id: "profile", label: "Profile" },
	{ id: "proposal", label: "Proposal" },
	{ id: "q_and_a", label: "Q&A" },
	{ id: "report", label: "Report" },
	{ id: "research_paper", label: "Research Paper" },
	{ id: "review", label: "Review" },
	{ id: "script", label: "Script" },
	{ id: "social_media_post", label: "Social Media Post" },
	{ id: "speech", label: "Speech" },
	{ id: "step_by_step", label: "Step-by-step" },
	{ id: "summary", label: "Summary" },
	{ id: "table", label: "Table" },
	{ id: "technical_documentation", label: "Technical Documentation" },
	{ id: "tutorial", label: "Tutorial" },
	{ id: "user_guide", label: "User Guide" },
	{ id: "webinar", label: "Webinar" },
	{ id: "whitepaper", label: "Whitepaper" },
	{ id: "ebook", label: "eBook" },
];

export default function Controls({
	onToneChange,
	onStyleChange,
	onFormatChange,
}: {
	onToneChange: (tone: string) => void;
	onStyleChange: (style: string) => void;
	onFormatChange: (format: string) => void;
}) {
	return (
		<div className="grid grid-cols-2 space-x-4 mb-4">
			<div>
				<Select
					onChange={onToneChange}
					label="GPT Tone"
					options={toneOptions}
				/>
			</div>
			<div>
				<Select
					onChange={onStyleChange}
					label="GPT Style"
					options={styleOptions}
				/>
			</div>
			<div className="hidden">
				<Select
					onChange={onFormatChange}
					label="GPT Format"
					options={formatOptions}
				/>
			</div>
		</div>
	);
}
