import { useState } from "react";

export default function Select({
	label = "Select",
	options = [],
	onChange,
}: {
	label: string;
	options: { id: string; label: string }[];
	onChange: (value: string) => void;
}) {
	const [selected, setSelected] = useState(options[0]);
	const handleOnChange = (value: { id: string; label: string }) => {
		setSelected(value);
		onChange(value.id);
	};

	return (
		<div>
			<label
				htmlFor="location"
				className="block text-sm font-medium leading-6 text-gray-900 min-w-full ml-0.5"
			>
				{label}
			</label>
			<select
				id="value"
				name="value"
				className="mt-1 block min-w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
				onChange={(e) =>
					handleOnChange(
						options.find((o) => o.id === e.target.value) || options[0],
					)
				}
				defaultValue={selected.id}
			>
				{options.map((option) => (
					<option key={option.id} value={option.id}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
}
